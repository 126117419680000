<!-- Author: Wilson Pilco Nunez -->
<!-- Email: wilsonaux1@gmail.com -->
<!-- Created at: 2025-02-04 10:45 -->
<!-- Description:  -->
<template>
  <div>
    <ul class="nav nav-tabs mb-3">
      <li class="nav-item">
        <a class="nav-link" :class="{'active': tabPos === 'quote'}" @click="tabPos = 'quote'">Cita</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" :class="{
          'active': tabPos === 'lock',
          'disabled': time_unit.quotes.length > 0 || time_unit.status === ''
        }" @click="tabPos = 'lock'; loadDisableTimes = true;">
          Bloquear
        </a>
      </li>
    </ul>
    <div v-show="tabPos === 'quote'">
      <MiniForm v-show="time_unit.quotes.length == 0" ref="elMiniForm" :interval="interval" :employee_id="employee_id"
        @submitted="$emit('change', 'quoteCreated')" :group="group"></MiniForm>
      <template v-for="l in time_unit.quotes">
        <div class="p-3 border" :key="l.id">
          <QuoteResume :quote="l"></QuoteResume>
          <div>
            <button class="btn btn-outline-primary mr-1" @click="$refs.elModalChangeDate.show(); $refs.elQuoteForm.setValue(l)">
              <i class="fas fa-calendar"></i>
              Cambiar Fecha</button>
            <button class="btn btn-outline-primary" @click="$refs.elModalChangeDuration.show(); $refs.elChangeDuration.load(l)">
              <i class="fas fa-clock"></i>
              Cambiar Duracion</button>
          </div>
        </div>
      </template>
    </div>
    <div v-show="tabPos === 'lock'">
      <DisableTimesList v-if="loadDisableTimes" :employee_id="employee_id" :datetime="time_unit.datetime"
        :interval="interval" @unlockTime="$emit('change', 'unlockTime')" @lockTime="$emit('change', 'lockTime')">
      </DisableTimesList>
    </div>
    <app-modal-basic ref="elModalAlert">
      <div class="alert alert-warning mb-3">
        <h4 class="alert-heading">Aviso!</h4>
        Agendar en un horario no habilitado debe ser coordinado con el profesional relacionado. Continuar de todas
        formas?
      </div>
      <button class="btn btn-primary mr-2" type="button" @click="$refs.elModalAlert.hide()">Continuar</button>
      <button class="btn btn-secondary" @click="$emit('cancel')">Cancelar</button>
    </app-modal-basic>

    <app-modal-basic ref="elModalChangeDate" :xl="true">
      <QuoteForm ref="elQuoteForm" @saved="$emit('change')"></QuoteForm>
    </app-modal-basic>

    <app-modal-basic ref="elModalChangeDuration">
      <ChangeDuration ref="elChangeDuration" @saved="$emit('change')"></ChangeDuration>
    </app-modal-basic>
  </div>
</template>
<script>
// script content
import miniForm from './mini-form.vue';
import myMoment from 'src/lt/utils/myMoment';
import QuoteResume from "./QuoteResume.vue";
import DisableTimesList from "./DisableTimesList.vue";
import QuoteForm from "./Form.vue";
import ChangeDuration from "./change-duration.vue";

export default {
  name: "TimeOptionCtrl",
  components: {
    'MiniForm': miniForm,
    QuoteResume,
    DisableTimesList,
    QuoteForm,
    ChangeDuration,
    // Comments,
  },

  // directives

  props: {
    interval: {},
    employee_id: {},
    group: {},
    time_unit: {
      default: () => ({
        datetime: null,
        status: null,
        quotes: [],
      })
    },
  },

  data: () => ({
    tabPos: "quote",
    showAlertNoSpected: false,
    loadDisableTimes: false,
  }),

  computed: {
    //
  },

  // watch: {},

  mounted() {
    if (this.time_unit.status === '') {
      this.showAlertNoSpected = true;
      this.$refs.elModalAlert.show();
    }
    if (this.time_unit.status === 'locked') {
      this.showAlertNoSpected = false;
      this.loadDisableTimes = true;
      this.tabPos = 'lock';
    }
    this.$refs.elMiniForm.setIniTime(
      myMoment(this.time_unit.datetime).toDate()
    );
  },

  methods: {

    //
    //
  }
};
</script>
<style scoped></style>
