<template>
  <div class="table-responsive">
    <table class="table table-sm table-bordered">
      <thead>
        <app-tr-weekpicker
          :dayToBegin="1"
          ref="weekPicker"
          @changeWeek="setCurrentTime($event, false)"
        ></app-tr-weekpicker>
      </thead>
      <tbody>
        <tr v-show="loading">
          <td></td>
          <td colspan="8">
            <i class="fas fa-spinner fa-spin fa-fw"></i> Cargando
          </td>
        </tr>
        <tr v-for="(l, i) in day_times" :key="i">
          <td class="text-center col-auto">{{ l.time }}</td>
          <template v-for="(l1) in date_range">
            <template v-if="times[`${l1.dayOfWeek}_${l.time}`] && times[`${l1.dayOfWeek}_${l.time}`].status !== 'avoid'">
              <WeekFormTd :key="`${l1.dayOfWeek}_${l.time}`"
                style="width: 12%;"
                @click="tdClickHandler(`${l1.dayOfWeek}_${l.time}`)"
                :param="times[`${l1.dayOfWeek}_${l.time}`]">
              </WeekFormTd>
            </template>
            <!-- <td-week-form -->
            <!--   style="display: table-cell;" -->
            <!--   :key="`${l1.dayOfWeek}_${l.time}`" -->
            <!--   v-if="!times[`${l1.dayOfWeek}_${l.time}`] || times[`${l1.dayOfWeek}_${l.time}`].mount" -->
            <!--   :unitTime="times[`${l1.dayOfWeek}_${l.time}`]" -->
            <!--   :timeOfDay="l.time" -->
            <!--   :dayOfWeek="l1.dayOfWeek" -->
            <!--   :currentTime="currentTime" -->
            <!--   @disableTime="disableTime($event)" -->
            <!--   @click=" -->
            <!--     $refs.elModalTimeOption.show(); -->
            <!--     selectedTime = { -->
            <!--       date: l1.date, -->
            <!--       time: l.time, -->
            <!--       datetime: `${l1.date} ${l.time}`, -->
            <!--       day_week: l1.dayOfWeek, -->
            <!--       available: times[`${l1.dayOfWeek}_${l.time}`] ? times[`${l1.dayOfWeek}_${l.time}`].free : false, -->
            <!--       quote: times[`${l1.dayOfWeek}_${l.time}`] ? times[`${l1.dayOfWeek}_${l.time}`].firstQuote : null, -->
            <!--     }; -->
            <!--   " -->
            <!--   @changeState=" -->
            <!--     $refs.elChangeSate.show(); -->
            <!--     quote = $event; -->
            <!--   " -->
            <!--   @showToEnable=" -->
            <!--     $refs.elModalEnableTime.show(); -->
            <!--     $refs.elModalEnableTime.val = $event; -->
            <!--   " -->
            <!--   @makeQuote=" -->
            <!--     $refs.elModalQuoteForm.show(); -->
            <!--     $refs.elMiniForm.setIniTime($event); -->
            <!--   " -->
            <!--   @changeDuration=" -->
            <!--     $refs.elModalChangeDuration.show(); -->
            <!--     $refs.elChangeDuration.load($event); -->
            <!--   " -->
            <!--   @changeDate=" -->
            <!--     $refs.elModalChangeDate.show(); -->
            <!--     $refs.elChangeDate.load($event); -->
            <!--   " -->
            <!-- > -->
            <!--   <template slot="menu" scope="{ quote }"> -->
            <!--     <a -->
            <!--       href="#" -->
            <!--       class="dropdown-item" -->
            <!--       @click.prevent="$emit('ortControl', quote)" -->
            <!--       v-show="quote.specialty_id == 3" -->
            <!--     > -->
            <!--       Control de Ortodoncia -->
            <!--     </a> -->
            <!--   </template> -->
            <!-- </td-week-form> -->
          </template>
          <td class="text-center col-auto">{{ l.time }}</td>
        </tr>
      </tbody>
    </table>

    <app-modal-basic ref="elModalChangeDuration">
      <change-duration
        :interval="unit_time"
        ref="elChangeDuration"
        @submitted="
          $refs.elModalChangeDuration.hide();
          reload();
        "
      ></change-duration>
    </app-modal-basic>

    <app-modal-yn
      ref="elModalEnableTime"
      @yes="enableTime($refs.elModalEnableTime.val)"
    >
      <p>Reactivar este intervalo de tiempo?</p>
    </app-modal-yn>

    <app-modal-basic ref="elModalQuoteForm">
      <h4>AGENDAR</h4>
      <mini-form
        ref="elMiniForm"
        :interval="unit_time"
        :employee_id="employee_id"
        :group="group"
        @submitted="
          $refs.elModalQuoteForm.hide();
          reload();
        "
      ></mini-form>
    </app-modal-basic>

    <!-- <app-modal-basic ref="elChangeSate"> -->
    <!--   <select-status -->
    <!--     :value="quote.status_id" -->
    <!--     :lastChangeTime="quote.updated_at" -->
    <!--     @input=" -->
    <!--       quote.status_id = $event; -->
    <!--       saveQuote(); -->
    <!--     " -->
    <!--   ></select-status> -->
    <!-- </app-modal-basic> -->

    <app-modal-basic ref="elModalDisableTime">
      <disable-time
        ref="elDisableTime"
        :employee_id="employee_id"
        :interval="unit_time"
        @submitted="
          $refs.elModalDisableTime.hide();
          reload();
        "
      ></disable-time>
    </app-modal-basic>

    <app-modal-basic ref="elModalChangeDate" :xl="true">
      <change-date
        ref="elChangeDate"
        @saved="
          $refs.elModalChangeDate.hide();
          reload();
        "
      ></change-date>
    </app-modal-basic>

    <app-modal-basic ref="elModalTimeOption" :md="true">
      <TimeOptionCtrl
        v-if="selectedTime && selectedTime.datetime"
        :key="selectedTime.datetime"
        :interval="unit_time"
        :employee_id="employee_id"
        :group="group"
        :time_unit="selectedTime"
        @change="reload(); $refs.elModalTimeOption.hide(); selectedTime = {}"
        @cancel="$refs.elModalTimeOption.hide()"
      >
      </TimeOptionCtrl>
    </app-modal-basic>

    <!-- <week-form-menu ref="elMenu"></week-form-menu> -->
  </div>
</template>
<script>
import { myMoment, momentDateFormat, momentDateTimeFormat } from "../../utils/myMoment";
import { DentalClinicService } from "../service";
import { mapState } from "vuex";
import { ServiceFactory } from "../../service-module/service";
import { dayTimes } from "../../base-module/day-times";
import miniForm from "./mini-form";
// import tdWeekForm from "./td-week-form.vue";
// import $ from "jquery";
import DisableTime from "./disable-time.vue";
// import weekFormMenu from "./week-form-menu";
// import selectStatus from "../../service-module/quotes/SelectStatus";
import changeDate from "./change-date";
import changeDuration from "./change-duration";
import { routeMix } from "../../base-module";
import TimeOptionCtrl from "./TimeOptionCtrl.vue";
import WeekFormTd from "./WeekFormTd.vue";
import {AdminService} from "src/admin-module/AdminService.js";

export default {
  mixins: [routeMix],
  components: {
    miniForm,
    // tdWeekForm,
    DisableTime,
    // weekFormMenu,
    // selectStatus,
    changeDate,
    changeDuration,
    TimeOptionCtrl,
    WeekFormTd,
  },

  // directives
  // filters

  props: {
    employee_id: {},
    group: {},
  },

  data: () => ({
    currentTime: myMoment(),
    unit_time: 60,
    day_times: [],
    times: {},
    quote: {},
    timeToBegin: "",
    timeToEnd: "",
    loading: false,
    selectedTime: {},
    date_range: [],
  }),

  computed: {
    ...mapState({
      defaultTimeToBegin: (s) => s.config.dental.employees.hour_to_begin,
    }),
  },

  watch: {
    //
  },

  created() {},

  mounted() {
    if (this.$route.query.date) {
      let timeToIn = myMoment(this.$route.query.date).toDate();
      this.$refs.weekPicker.setDate(timeToIn);
      this.currentTime = timeToIn;
    }
    // config load times
    this.getConfig();
  },

  methods: {
    enableTime(datetime) {
      DentalClinicService.disableScheduleTime({
        enableTime: 1,
        employee_id: this.employee_id,
        datetime: datetime,
        duration: this.unit_time
      }).then(() => {
        this.$refs.elModalEnableTime.hide();
        this.reload();
      });
    },
    saveQuote() {
      DentalClinicService.saveQuote({
        ...this.quote,
        update_status: 1
      });
    },
    reload() {
      this.getTimes();
    },
    disableTime(e) {
      this.$refs.elModalDisableTime.show();
      this.$refs.elDisableTime.setIniTime(e);
    },
    // getDateFromWeekDay() {
    // // },
    // showFreeMenu({ datetime, el }) {

    //   // this.$refs.elFreeMenu
    // },
    setCurrentTime(newTime = new Date(), external = true) {
      if (external) {
        this.$refs.weekPicker.setDate(myMoment(newTime).format(momentDateFormat));
      }
      this.currentTime = myMoment(newTime);
      this.getConfig();
      this.routeMixAddQueryParam(
        "date",
        myMoment(newTime).format(momentDateFormat)
      );
      this.$emit("changeTime", newTime);
    },
    loadDateRanges() {
      let date_range = [];
      let itMoment = myMoment(this.$refs.weekPicker.getBeginDate());
      for(let it of [1, 2, 3, 4, 5, 6, 0]) {
        date_range.push({
          date: itMoment.format("YYYY-MM-DD"),
          dayOfWeek: it,
        });
        itMoment.add(1, "day");
      }
      this.date_range = date_range;
    },
    darCita(dayOfWeek, utime) {
      var oFecha = myMoment("00", "HH");
      oFecha.add(-oFecha.day() + dayOfWeek, "days");
      this.$refs.miniform.setIniTime(oFecha.format("YYYY/MM/DD") + " " + utime);
      this.$refs.dmf.show();
    },
    getConfig() {
      DentalClinicService.getEmployeeConfig(this.employee_id).then((res) => {
        this.unit_time = res.interval_service;
        this.getTimes();
      });
    },
    async getTimes() {
      this.loading = true;
      // conseguir las citas
      let [quotes, schedules, disableTimes] = await Promise.all([
        DentalClinicService.getQuotes({
          employee_id: this.employee_id,
          ini_date: this.$refs.weekPicker.getBeginDate(),
          end_date: this.$refs.weekPicker.getLastDate(),
          per_page: 9999,
        }),
        AdminService.getEmployeeSchedules({
          employee_id: this.employee_id,
          per_page: 1,
          date: this.$refs.weekPicker.getBeginDate(),
        }),
        DentalClinicService.getDisableScheduleTimes({
          employee_id: this.employee_id,
          ini_time: this.$refs.weekPicker.getBeginDate(),
          end_time: this.$refs.weekPicker.getLastDate(),
        })
      ]);
      let currentSchedule = {};
      if(schedules.data.length > 0) {
        currentSchedule = schedules.data[0];
      }
      this.loadDateRanges(); // cargado fechas de la semana [cols]
      this.timeToBegin = "08:00";
      this.timeToEnd = "23:59";
      // aqui vamos a obtener el maximo y minimo de horarios
      for(let sch of schedules.data) {
        this.timeToBegin = sch.times.reduce((p,c) => {
          if(c.ini_time >= c.end_time) return p;
          if(c.ini_time == null) return p;
          if(!sch[`d${c.day_of_week}`]) return p;
          return c.ini_time < p ? c.ini_time : p;
        }, "99:99:99");
      }
      if(this.timeToBegin == "99:99:99") this.timeToBegin = "08:00";
      this.updateTimes(); // cargada lista de tiempos [rows]
      let times = {}; // el horario por claves
      quotes.data = quotes.data.filter(x => {
        return x.status_id !== 10 && x.status_id !== 9;
      });
      this.$emit('quotesCounter', [
        quotes.data.filter(x => x.group !== 2).length,
        quotes.data.filter(x => x.group === 2).length
      ]);
      let quotesCounter = quotes.data.length;
      for(let it1 in this.day_times) {
        let row = this.day_times[it1];
        for(let col of this.date_range) {
          let timeKey = `${col.dayOfWeek}_${row.time}`;
          let currentDateTime = `${col.date} ${row.value}`;
          // se omiten citas anuladas y reagendadas
          let currentQuotes = quotes.data.filter(x => {
            return x.ini_date == `${col.date} ${row.value}`;
          }).sort((a, b) => {
            return a.group > b.group ? 1 : -1;
          });
          // si ya existe su registro, no deberia de hacer nada
          if(times[timeKey]) {
            // console.log("ommit in " + timeKey);
            times[timeKey].datetime = `${col.date} ${row.value}`;
            continue;
          }
          let quotesByGroup = currentQuotes.filter(x => x.group == this.group);
          let firstQuote = quotesByGroup.length > 0 ? quotesByGroup[0] : {};
          let status = '';
          // checkea si el dia esta habilitado
          if(currentSchedule[`d${col.dayOfWeek}`]) {
            let timesOnDay = currentSchedule.times.filter(x => 
              x.day_of_week == col.dayOfWeek && x.ini_time <= row.value && x.end_time > row.value
            );
            if(timesOnDay.length > 0) {
              // console.log("row ini time end time : " + row.time, timesOnDay);
              status = 'available';
            }
          }
          if(quotesByGroup.length > 0) {
            status = 'quoted';
          }
          if(
            disableTimes.data.length > 0 
            && disableTimes.data.filter(
              x => x.ini_time <= currentDateTime && x.end_time > currentDateTime).length > 0
          ) {
            status = 'locked';
          }
          times[timeKey] = {
            quotes: quotesByGroup,
            overload: currentQuotes.filter(x => x.group == 2).length > 0,
            status,
            rowspan: firstQuote.intervals,
            datetime: currentDateTime
          };
          if(firstQuote.intervals > 1) {
            // console.log("need set intervals in future");
            for(let i2=1; i2<firstQuote.intervals; i2++) {
              // got to next pos
              // console.log("set in pos " + timeKey + " // " + i2 + " // " + (it1 + i2));
              if(this.day_times[parseInt(it1) + parseInt(i2)]) {
                let nextPos = this.day_times[parseInt(it1) + parseInt(i2)];
                times[`${col.dayOfWeek}_${nextPos.time}`] = {
                  quotes: [],
                  status: 'avoid',
                  rowspan: 0,
                }
              }
            }
          }
          
        }
      }
      // for (let l of quotes.data) {
      //   let iniTimeInstance = myMoment(l.ini_date);
      //   let timeKey = `${iniTimeInstance.day()}_${iniTimeInstance.format("HH:mm")}`;
      //   times[timeKey] =   {
      //     dayOfWeek: iniTimeInstance.day(),
      //     time: iniTimeInstance.format("HH:mm:ss"),
      //     datetime: iniTimeInstance.format(momentDateTimeFormat),    
      //     disabled: false,  
      //     free: false,  
      //     quotes: 0,   
      //     overQuotes: 0, 
      //     mount: true,
      //     firstQuote: l,
      //     firstQuoteId: l.id,
      //     disableExcuse: null,
      //     duration: l.duration
      //   };
      //   if(l.intervals > 1) {
      //     for(let i=1; i<l.intervals; i++) {
      //       iniTimeInstance.add(this.unit_time, "minute");
      //       if(times[timeKey] === undefined) {
      //         times[timeKey] = {
      //           dayOfWeek: iniTimeInstance.day(),  
      //           time: iniTimeInstance.format("HH:mm:ss"),
      //           datetime: iniTimeInstance.format(momentDateTimeFormat),    
      //           disabled: false,  
      //           free: false,  
      //           quotes: 0,   
      //           overQuotes: 0, 
      //           mount: false,
      //           firstQuote: l,
      //           firstQuoteId: l.id,
      //           disableExcuse: null,
      //           duration: l.duration
      //         }
      //       }
      //     }
      //   }
      // }
      this.$store.commit("dental/setQuotes", quotesCounter);
      this.times = times;
      this.loading = false;
    },
    updateTimes() {
      // generate first col of times
      this.day_times = dayTimes.filter((x, y) => {
        return (
          y % this.unit_time == 0 &&
          (this.timeToBegin ? this.timeToBegin <= x.value : true) &&
          (this.timeToEnd ? this.timeToEnd >= x.value : true)
        );
      });
    },
    tdClickHandler(timeKey) {
      this.$refs.elModalTimeOption.show();
      this.selectedTime = this.times[timeKey];
    }
  }
};
</script>

<style>
.app_td_week_with {
  max-width: 11.4%;
}
</style>
