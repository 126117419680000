<template>
  <div>
    <div class="row">
      <div class="col">
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <a class="nav-link" @click="group = 1" :class="{active: group == 1}">
              Agenda
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" @click="group = 2" :class="{active: group == 2}">
              SobreAgenda
              <span class="badge badge-primary">{{ counter[1] }}</span>
            </a>
          </li>
        </ul>
      </div>
      <div class="col">
        <app-datepicker v-model="currentDate" @input="setTime($event)"></app-datepicker>
      </div>
      <div class="col">
        <select-employee :disabled="true" ref="selEmp" v-model="ins_employee_id"></select-employee>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <week-form ref="weekForm" @ortControl="
          $refs.elModalOrtCtrl.show();
        $refs.elOrtControl.load($event);
        " :group="group" :employee_id="employee_id" :key="`${employee_id}_${group}`"
          @quotesCounter="counter = $event"></week-form>
      </div>
    </div>

    <app-modal-basic ref="elModalOrtCtrl">
      <!-- <ort-control
        @submit="
          $refs.elModalOrtCtrl.hide();
          addQueryParam('to_make', )
        "
        ref="elOrtControl"
      ></ort-control> -->
    </app-modal-basic>
  </div>
</template>
<script>
import weekForm from "./week-form";
import selectEmployee from "../../admin-module/employees/Select";
// import ortControl from "../agenda/ortControl";
import {routeMix} from "../../base-module";
import {myMoment} from "../../utils/myMoment";

export default {
  mixins: {
    routeMix
  },
  components: {
    weekForm,
    selectEmployee,
    // ortControl
  },

  // directives
  // filters

  props: {
    employee_id: {}
  },

  data: () => ({
    group: 1,
    currentDate: myMoment().format("YYYY-MM-DD"),
    ins_employee_id: null,
    counter: [0, 0],
  }),

  computed: {
    //
  },

  mounted() {
    this.setEmployee();
  },

  methods: {
    setTime(date) {
      this.$refs.weekForm.setCurrentTime(myMoment(date).toDate(), true);
    },
    setEmployee() {
      this.$refs.selEmp.setValueFromId(this.employee_id);
    }
  }
};
</script>

<style>
</style>
