<!-- Author: Wilson Pilco Nunez -->
<!-- Email: wilsonaux1@gmail.com -->
<!-- Created at: 2025-02-04 19:50 -->
<!-- Description:  -->
<template>
  <div>
    <table class="table table-borderless table-sm">
      <tbody>
        <tr>
          <th>Paciente:</th>
          <td><router-link :to="`/dental-clinic/patients/${quote.client_id}`">{{ quote.client_name }}</router-link></td>
        </tr>
        <tr>
          <th>Hora:</th>
          <td>Desde las {{ formatTime(quote.ini_date) }} hasta {{ formatTime(quote.end_date) }}</td>
        </tr>
        <tr>
          <th>Comentarios:</th>
          <td>
            <span>{{ quote.commentary }}</span>
            <CommentForm class="mt-2" :quote_id="quote.id"></CommentForm>
          </td>
        </tr>
        <tr>
          <th>Estado:</th>
          <td>
            <SelectStatus :value="quote.status_id" :lastChangeTime="quote.updated_at"
              :disabled="!hasPermission('srv-quotes-change-status')" @input=" quote.status_id = $event; saveQuote()">
            </SelectStatus>
          </td>
        </tr>
      </tbody>
    </table>

  </div>
</template>
<script>
// script content
import CommentForm from "./CommentForm.vue"
import SelectStatus from "src/service-module/quotes/SelectStatus.vue";
import {DentalClinicService} from "../service.js";
import {myMoment} from "../../utils/myMoment.js";
import hasPermissionMixin from "src/lt/utils/hasPermissionMixin.js";

export default {
  mixins: [hasPermissionMixin],
  name: "QuoteResume",
  components: {
    CommentForm,
    SelectStatus,
  },

  // directives

  props: {
    quote: {
      default: () => ({}),
    }
  },

  data: () => ({
    //
  }),

  computed: {
    //
  },

  // watch: {},

  mounted() {
    //
  },

  methods: {
    saveQuote(quote) {
      DentalClinicService.saveQuote({
        ...this.quote,
        update_status: 1
      });
    },
    formatTime(time) {
      return myMoment(time).format("hh:mma");
    }
    //
    //
  }
};
</script>
<style scoped></style>
