<template>
  <div class="shadow-sm">
    <Nav></Nav>
    <weekly-agenda class="bg-white p-2" :employee_id="employee_id" :key="employee_id"></weekly-agenda>
  </div>
</template>
<script>
// import WeekForm from "./quotes/week-form";
import Nav from "./Navbar.vue";
// import selectEmployee from "../../admin-module/employees/Select";
import weeklyAgenda from "../quotes/weekly-agenda.vue";
// import ortControl from "./ortControl";

export default {
  components: {
    // WeekForm,
    Nav,
    // SelectEmployee,
    weeklyAgenda
    // ortControl
  },

  // directives
  // filters

  props: {
    employee_id: {}
  },

  data: () => ({
    //
  }),

  computed: {
    //
  },

  watch: {
    //
  },

  mounted() {
    //
  },

  methods: {
    //
  }
};
</script>

<style>
</style>
