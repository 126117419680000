<template>
  <div>
    <h4>BLOQUEAR HORARIO</h4>
    <div class="form-group">
      <label for="">Tiempo</label>
      <SelectDuration
        :employeeId="employee_id"
        :iniTime="iniTime"
        :interval="interval"
        v-model="duration"
      ></SelectDuration>
    </div>
    <div class="form-group">
      <label for="">Sustento</label>
      <app-textarea v-model="excuse"></app-textarea>
    </div>
    <app-button-submit @click="save()"></app-button-submit>
  </div>
</template>
<script>
import SelectDuration from "./select-duration.vue";
import { DentalClinicService } from "../service";
import { myMoment } from "../../utils/myMoment";

export default {
  components: {
    SelectDuration
  },

  // directives
  // filters

  props: {
    employee_id: {},
    interval: {},
    datetime: {},
  },

  data: () => ({
    duration: null,
    iniTime: null,
    excuse: ""
  }),

  computed: {
    //
  },

  watch: {
    //
  },

  mounted() {
    this.iniTime = this.datetime;
  },

  methods: {
    save() {
      let endTime = myMoment(this.iniTime).add(this.duration, "minute");
      DentalClinicService.disableScheduleTime({
        iniTime: myMoment(this.iniTime).format("YYYY-MM-DD HH:mm"),
        employee_id: this.employee_id,
        endTime: endTime.format("YYYY-MM-DD HH:mm"),
        excuse: this.excuse
      }).then(() => {
        this.$emit("submitted");
      });
    },
    setIniTime(iniTime) {
      this.reset();
      this.iniTime = iniTime;
    },
    reset() {
      this.duration = null;
      this.excuse = "";
    }
  }
};
</script>

<style></style>
