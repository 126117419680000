<!-- Author: Wilson Pilco Nunez -->
<!-- Email: wilsonaux1@gmail.com -->
<!-- Created at: 2025-02-07 01:09 -->
<!-- Description:  -->
<template>
  <td class="position-relative p-0 button" :class="{td_shadow: currentQuote.id}" :rowspan="param.rowspan"
    v-quote-bg-color="currentQuote.status_id" @click="clickHandler($event)"
    @mouseover="firstClick=false">
    <i
      v-show="param.overload"
      class="fa fa-bolt pull-right td_q_light"
      data-session-id="43606"
    ></i>
    <div class="" :class="{div_nowrap: currentQuote.id}">
      <a href="javascript:void(0)" class="text-reset" :title="currentQuote.client_name">
        <small v-show="currentQuote.id">{{ currentQuote.client_name }}</small>
      </a>
      <a href="javascript:void(0)" v-if="param.status == 'available'"
        class="alert alert-success-phantom text-right m-0 rounded-0 p-1 d-block">
        <i class="fa fa-plus-circle"></i>
      </a>
      <a href="javascript:void(0)" v-if="param.status == 'locked'"
        class="alert alert-secondary disabled text-right m-0 rounded-0 p-1 d-block">
        <i class="fas fa-comment"></i>
      </a>
    </div>

    <comment
      class="app_comment"
      v-if="currentQuote.id"
      :comment="currentQuote.commentary"
      :quote_id="currentQuote.id"
    ></comment>
  </td>
</template>
<script>
// script content
import {quoteBgColor} from "./quote-bg-color-directive";
import Comment from "./Comments";
export default {
  name: "WeekFormTd",
  components: {
    Comment,
  },

  directives: {

    quoteBgColor
  },

  props: {
    param: {}
  },

  data: () => ({
    firstClick: false,
  }),

  computed: {
    currentQuote() {
      return this.param.quotes.length > 0 ? this.param.quotes[0] : {};
    }
  },

  // watch: {},

  mounted() {
    //
  },

  methods: {
    clickHandler(ev) {
      if( !this.firstClick ) {
        this.firstClick = true;
      } else {
        this.firstClick = false;
        this.$emit('click', ev);
      }
    }
    //
    //
  }
};
</script>
<style scoped>
.div_nowrap {
  height: 100%;
  width: 139px;
  padding: 0.2rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.app_dt_no_arrow:after {
  display: none;
}

.alert-success-phantom {
  color: #155724;
  background-color: #d4edda;
}

.td_q_light {
  margin-right: 0px;
  color: #fff;
  font-size: 9px;
  margin-top: 4px;
  position: absolute;
  left: -5px;
  top: -5px;
  background: rgb(255, 112, 0);
  padding: 2px 2px;
  z-index: 1;
  border-radius: 4px;
}

.td_shadow {
  -webkit-box-shadow: inset 0 0.225rem 0.225rem rgba(0, 0, 0, 0.075) !important;
  box-shadow: inset 0 0.225rem 0.225rem rgba(0, 0, 0, 0.075) !important;
}

.app_comment {
  position: absolute;
  right: 0.2rem;
  top: 0.2rem;
}
</style>

