<!-- Author: Wilson Pilco Nunez -->
<!-- Email: wilsonaux1@gmail.com -->
<!-- Created at: 2025-02-04 19:12 -->
<!-- Description:  -->
<template>
  <div>
    <div v-if="comments">
      <div class="mb-1 text-dark" v-for="(l, i) in comments" :key="i">
        <b>{{ l.user_name }} : </b> <span> {{ l.comment }} </span>
      </div>
    </div>
    <div class="form-group">
      <!-- <label for>Comentar:</label> -->
      <app-textarea v-model="new_comment" rows="1"></app-textarea>
    </div>
    <app-button-submit @click="save()">Comentar</app-button-submit>
  </div>
</template>
<script>
import $ from "jquery";
import { DentalClinicService } from "../service";
import myMoment from "../../utils/myMoment.js";

const rules = {}
export default {
  name: "CommentForm",
  components: {
    //
  },

  // directives

  props: {
    quote_id: {},
    disabled: {default: false},
  },

  data: () => ({
    sending: false,
    showingPopover: false,
    count: 0,
    comments: undefined,
    status: undefined,
    new_comment: undefined
  }),

  computed: {
    errors() {return false;}
  },

  // watch: {},

  mounted() {
    this.getComments()
  },

  methods: {
    reset() {this.sending = false;},
    setValue() {},
    save() {
      DentalClinicService.saveQuoteComment({
        quote_id: this.quote_id,
        comment: this.new_comment
      }).then(() => {
        this.new_comment = "";
        this.getComments();
      });
    },
    getComments() {
        DentalClinicService.getQuoteComments({
          quote_id: this.quote_id
        }).then(res => {
          this.comments = res;
        });
    },
    showPopover() {
      $(this.$refs.popover).popover("show");
      this.showingPopover = true;
    },
    hidePopover() {
      $(this.$refs.popover).popover("hide");
      this.showingPopover = false;
    }
  }
};
</script>
<style scoped>
</style>

