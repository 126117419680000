<!-- Author: Wilson Pilco Nunez -->
<!-- Email: wilsonaux1@gmail.com -->
<!-- Created at: 2025-02-06 19:59 -->
<!-- Description:  -->
<template>
  <div>
    <div class="alert alert-info" v-for="l in disables" :key="l.id">
      <h4 class="alert-header">Bloqueado</h4>
      <div>{{ l.excuse }}</div>

      <div class="mt-3">
        <button class="btn btn-secondary" @click="enableTime(l.id)">Habilitar</button>
      </div>
    </div>
    <DisableTime v-show="disables.length == 0" :employee_id="employee_id" :interval="interval" :datetime="datetime"
      @submitted="$emit('lockTime')">
    </DisableTime>
  </div>
</template>
<script>
// script content
import {DentalClinicService} from "../service";
import DisableTime from "./disable-time.vue";
export default {
  name: "DisableTimesList",
  components: {
    DisableTime,
  },

  // directives

  props: {
    employee_id: {},
    datetime: {},
    interval: {},
  },

  data: () => ({
    disables: []
  }),

  computed: {
    //
  },

  // watch: {},

  mounted() {
    this.reload();
  },

  methods: {
    async reload() {
      let res = await DentalClinicService.getDisableScheduleTimes({
        employee_id: this.employee_id,
        ini_time: this.datetime,
        end_time: this.datetime,
        per_page: 1,
      });
      this.disables = res.data;
    },
    enableTime(id) {
      DentalClinicService.deleteDisableScheduleTime(id).then(() => {
        // this.reload();
        this.disables = [];
        this.$emit("unlockTime");
      })
    }
    //
  }
};
</script>
<style scoped>
</style>

